/* eslint-disable react/no-multi-comp */
import { Popover } from "@headlessui/react";
import ArtifactGridItem01 from "@public/artifact_grid_item_01_4x.png";
import ArtifactGridItem02 from "@public/artifact_grid_item_02_4x.png";
import ArtifactGridItem03 from "@public/artifact_grid_item_03_4x.png";
import ArtifactGridItem04 from "@public/artifact_grid_item_04_4x.png";
import ArtifactGridItem05 from "@public/artifact_grid_item_05_4x.png";
import ArtifactGridItem06 from "@public/artifact_grid_item_06_4x.png";
import ArtifactGridItem07 from "@public/artifact_grid_item_07_4x.png";
import ArtifactGridItem08 from "@public/artifact_grid_item_08_4x.png";
import _ from "lodash";
import Image from "next/image";
import React from "react";

import ArtifactPopoverButton from "./ArtifactPopoverButton";

import { clsMerge } from "@/utils/cls-merge";
import { inter } from "@/utils/font";
import { CancelIcon } from "@/utils/icon";

const artifactPosition = [47, 49, 66, 69, 72, 90, 91, 106];

const getPlacement = (index: number) => {
  switch (index) {
    case 47:
    case 49:
    case 66:
    case 69:
    case 72:
      return "left-start";
    default:
      return "left";
  }
};

const artifacts = [
  {
    id: 47,
    location: "A Deck",
    name: "Crow's Nest Bell",
    description: () => {
      return (
        <>
          One of three bells carried on TITANIC. This one was originally fitted in the Crow’s Nest
          (otherwise known as the look-out cage).
        </>
      );
    },
    imageSrc: ArtifactGridItem01,
  },
  {
    id: 49,
    location: "Captain’s Bridge",
    name: "Steering Stand",
    description: () => {
      return (
        <>
          The artifact held one of three steering wheels used to navigate the TITANIC. This one is
          from the Captain’s (Navigating) Bridge. The artifact is an assembly of several components
          used to support one of the ship’s teak steering wheels at a convenient height and to
          communicate mechanical steering commands by means of rotating shafts and bevel gears to
          the ship’s telemotor transmitter, which formed the hydraulic control for the ship’s steam
          steering gear under the poop deck.
        </>
      );
    },
    imageSrc: ArtifactGridItem08,
  },
  {
    id: 66,
    location: "A Deck",
    name: "Loud Speaking Telephone",
    description: () => {
      return (
        <>
          A telephone used between working compartments on TITANIC. The phone works much like an
          intercom: a single phone generally communicates with only one remote station, eliminating
          the need for a switchboard. Artifact has a round brass body with a megaphone-like
          mouthpiece and a junction box below. On the right and left were trumpet-shaped ear pieces
          with hinges for placement over the ears (only right extant). Controls include a push
          button to call the distant station and a lever switch for “talk” or “listen” mode.
        </>
      );
    },
    imageSrc: ArtifactGridItem02,
  },
  {
    id: 69,
    location: "Top Deck",
    name: "Davit Arm from the Lifeboat",
    description: () => {
      return (
        <>
          This Welin Davit set, one of 20 on TITANIC, was the safest and most advanced design of its
          time. Its “double acting” gearing allowed the davit to rotate over the deck of the ship to
          attach lifeboats and swing over the water for ease and swiftness of lowering. Because this
          davit was placed between lifeboats, there are actually two davit arms: one to control the
          bow of one boat, and a second to control the stern of another. Only one arm survives.
        </>
      );
    },
    imageSrc: ArtifactGridItem06,
  },
  {
    id: 72,
    location: "First Class Dining Room",
    name: "Hors D’oeuvres Dish",
    description: () => {
      return (
        <>
          An earthenware, three compartment dish used to serve Canapés and Hors D’oeuvres in the
          First Class Reception Room and Dining Room. This would have allowed stewards to circulate
          among passengers and offer them Canapés and Hors D’oeuvres before dinner. Most décor is
          now absent.
        </>
      );
    },
    imageSrc: ArtifactGridItem03,
  },
  {
    id: 72,
    location: "Restaurant Pantry (B Deck)",
    name: "“Special Service” Side Dish",
    description: () => {
      return (
        <>
          A fine china side plate, elaborately ornamented and the most expensive set discovered at
          the TITANIC wreck site. It is believed to be the dinner service for the À la Carte
          Restaurant, but because there are several competing theories as to use, this group goes by
          “Special Service.”
        </>
      );
    },
    imageSrc: ArtifactGridItem04,
  },
  {
    id: 90,
    location: "First Class Dining Room",
    name: "Chocolate Cup",
    description: () => {
      return (
        <>
          A large cup with decorations from the First Class Dining Room service, identified as a
          chocolate cup.
        </>
      );
    },
    imageSrc: ArtifactGridItem05,
  },
  {
    id: 106,
    location: "Forward Well Deck",
    name: "Deck Bench",
    description: () => {
      return (
        <>
          Originally part of one of many passenger benches scattered on the TITANIC’s upper decks
          and found in all classes. The artifact is the center frame that supports the bench’s
          wooden slats midway between the ends. The artifact consists of a single cast bronze piece
          that forms the center support for a passenger deck bench.
        </>
      );
    },
    imageSrc: ArtifactGridItem07,
  },
];

const artifactMap = _.zipObject(artifactPosition, artifacts);

const ArtifactInfo: React.FC<any> = ({
  index,
  disabled,
  dispatchIndex,
}: {
  index: number;
  disabled: boolean;
  dispatchIndex: any;
}) => {
  const [activeIndex, setActiveIndex] = React.useState(0);
  const slideContainerRef = React.useRef<any>();
  const slideRefs = React.useRef<any>([]);

  const DescriptionElement = React.useMemo(() => {
    return (
      artifactMap[index]?.description ||
      (() => {
        return <></>;
      })
    );
  }, [index]);

  React.useEffect(() => {
    const observer = new window.IntersectionObserver(
      entries => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            dispatchIndex(artifacts[slideRefs.current.indexOf(entry.target)].id);
            setActiveIndex(slideRefs.current.indexOf(entry.target));
            break;
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      },
    );

    for (const slide of slideRefs.current) {
      if (slide) {
        observer.observe(slide);
      }
    }

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slideContainerRef, slideRefs]);

  if (disabled) {
    index = 40;
  }

  return (
    <>
      <div
        className="scrollbar-hide -mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 lg:hidden [&::-webkit-scrollbar]:hidden"
        ref={slideContainerRef}
      >
        {artifacts.map(({ location, name, description: Description, imageSrc }, artifactIndex) => (
          <div
            className="text-black-[#121111] mx-auto flex w-full max-w-none flex-none snap-center flex-col overflow-y-scroll bg-white p-6 px-6 lg:max-w-[600px] lg:p-0"
            key={`chapter.container.${artifactIndex}`}
            ref={ref => (slideRefs.current[artifactIndex] = ref)}
          >
            <div className="mt-2 flex justify-center gap-3">
              {artifacts.map((_, chapterIndex: number) => (
                <button
                  aria-label={`Go to slide ${chapterIndex + 1}`}
                  className={clsMerge(
                    "relative h-2 w-2 cursor-default rounded-full",
                    chapterIndex === activeIndex ? "bg-gold" : "bg-[#BBBBBB]",
                  )}
                  key={`chapter.${chapterIndex}`}
                  type="button"
                  onClick={() => {
                    slideRefs.current[chapterIndex].scrollIntoView({
                      block: "nearest",
                      inline: "nearest",
                    });
                  }}
                >
                  <span className="absolute -inset-x-1.5 -inset-y-3" />
                </button>
              ))}
            </div>
            <div
              className={clsMerge(
                "mb-8 mt-8  grid min-w-[240px]  grid-cols-2 text-left",
                inter.className,
              )}
            >
              <div className="mr-3 flex max-w-[203px] flex-1 flex-col items-start">
                <h1 className="text-[20px] font-[700] leading-[32px]">Location</h1>
                <div className="text-[14px] font-[400] leading-[22px]">{location}</div>
              </div>
              <div className="ml-[10px] flex flex-1 flex-col">
                <h2 className="text-[20px] font-[700] leading-[32px]">Artifact</h2>
                <div className="text-[14px] font-[400] leading-[22px]">{name}</div>
              </div>
            </div>
            <div className="mt-[4px] grid grid-cols-2 gap-[22px] space-y-0 md:mt-[22px] ">
              <Image alt="" src={imageSrc} />
              <div
                className={clsMerge(
                  "text-left text-[14px] font-[500] leading-[23px] text-gold",
                  inter.className,
                )}
              >
                <Description />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="text-black-[#121111] scrollbar-hide mx-auto hidden max-w-none flex-col overflow-y-scroll bg-white p-6 lg:flex lg:max-w-[600px] lg:p-0">
        <div className={clsMerge("flex h-full min-w-[240px] text-left", inter.className)}>
          <div className="flex min-w-[214px] flex-col items-start">
            <h1 className="text-[20px] font-[700] leading-[32px]">Location</h1>
            <div className="text-[14px] font-[400] leading-[22px]">
              {artifactMap[index]?.location}
            </div>
          </div>
          <div className="ml-[10px] flex flex-col">
            <h2 className="text-[20px] font-[700] leading-[32px]">Artifact</h2>
            <div className="text-[14px] font-[400] leading-[22px]">{artifactMap[index]?.name}</div>
          </div>
        </div>
        <div className="mt-[22px] space-y-[22px] md:grid md:grid-cols-[203px_1fr] md:gap-[22px] md:space-y-0">
          <Image alt="" src={artifactMap[index]?.imageSrc} />
          <div
            className={clsMerge(
              "text-left text-[14px] font-[500] leading-[23px] text-gold",
              inter.className,
            )}
          >
            <DescriptionElement />
          </div>
        </div>
      </div>
    </>
  );
};

const Wreckage: React.FunctionComponent = () => {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  return (
    <div
      className={clsMerge(
        "mx-auto flex items-center justify-center text-center",
        "relative",
        "scroll-auto",
      )}
    >
      <div className="x-12 grid w-full grid-cols-19 border-[#5B5137CC]">
        {Array(207)
          .fill(0)
          .map((each, index) => (
            <ArtifactPopoverButton
              buttonClassName={clsMerge(
                "border-[#5B5137CC]",
                "aspect-[1/1] col-span-1 cursor-default outline-none",
                !artifactPosition.includes(index) &&
                  (index < 189 || index >= 207) &&
                  ![56, 75, 94, 113, 132, 151, 170, 189].includes(index) &&
                  "border-l-[1px] border-t-[1px] ",
                artifactPosition.includes(index) &&
                  index !== activeIndex &&
                  "bg-[#DFAE2A70] hover:bg-[#FFFFFF80] cursor-pointer",
                artifactPosition.includes(index) &&
                  index === activeIndex &&
                  "bg-[#DFAE2A70] lg:bg-[#FFFFFF80] lg:hover:bg-[#FFFFFF80] cursor-pointer",
                index > 0 && index <= 38 && "border-none", // first 2 row
                index > 189 &&
                  index <= 207 &&
                  "border-b-[0px] border-x-[0px] border-r-[0px] border-t-[1px] border-l-0", // last row
                [56, 75, 94, 113, 132, 151, 170, 189].includes(index) &&
                  "border-t-0 border-l-[1px]", // last col
                index == 188 && "border-b-[1px] border-r-[1px]", // last visible grid
                index % 19 == 0 && "border-none", // first col
              )}
              content={
                <ArtifactInfo
                  disabled={!artifactPosition.includes(index)}
                  dispatchIndex={setActiveIndex}
                  index={index}
                />
              }
              disabled={!artifactPosition.includes(index)}
              handleClose={() => {
                setActiveIndex(-1);
              }}
              handleOnClick={() => {
                setActiveIndex(index);
              }}
              key={`primary-intro-artifact-popover-button-${index}`}
              popoverConfig={{
                placement: getPlacement(index),
              }}
            />
          ))}
      </div>
    </div>
  );
};

export default Wreckage;
