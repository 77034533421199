import { FunctionComponent } from "react";

import { clsMerge } from "@/utils/cls-merge";
import { EBGaramond, inter } from "@/utils/font";

interface CommunityBlockProps {
  title: string;
  timestamp: string;
}

const CommunityBlock: FunctionComponent<CommunityBlockProps> = ({
  title,
  timestamp
}) => {
  return (
    <div className="h-[97px] border-b-[#191919] border-b-[1px] flex flex-col justify-center items-center">
      <div className={clsMerge(
        'self-stretch  text-titanic-gold-500 text-lg font-semibold uppercase leading-[27px] tracking-[2.70px]',
        EBGaramond.className
      )}>
        {title}</div>
      <div className="self-stretch justify-start items-start gap-6 inline-flex">
        <div className={clsMerge(
          'text-[#87888C] text-sm font-normal leading-[21px]',
          inter.className
        )}>
          {timestamp}
        </div>
      </div>
    </div>
  )
}

export default CommunityBlock;
